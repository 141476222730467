.SearchScreen {
  padding: 0 0 80px 0;
}

.SearchScreen-search-form {
  padding: 15px 0;
  background: $off-white;
}

@media only screen and (max-width: 999px) {
  .SearchScreen-search-form {
    padding-left: 10px;
  }
}

.SearchScreen-query,
.SearchScreen-button {
  display: inline-block;
  margin-right: 5px;
}

.SearchScreen-query {
  width: 170px;
}

.SearchScreen-users {
  border-top: 1px solid $very-muted;
  border-bottom: 1px solid $very-muted;
  padding: 10px 20px;
  background: $content-bg;
  line-height: 1.5;
}

@media #{$mobile-query} {
  .SearchScreen-search-form {
    display: flex;
    padding: 15px 10px;
  }

  .SearchScreen-query {
    flex: 1;
    margin: 0;

    input {
      width: 100%;
      height: 40px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  .SearchScreen-button {
    margin: 0;

    .Button {
      height: 40px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}