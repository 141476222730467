@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }

  &::-ms-input-placeholder {
    @content
  }

  &::placeholder {
    @content
  }
}