// Add overrides for default Chartist styles here
// Consult chartist/dist/scss/chartist/settings/_chartist-settings.scss
// for reference
$ct-line-width: 1px;
$ct-series-colors: (#48c39c,
#d70206,
#f05b4f,
#f4c63d,
#d17905,
#453d3f,
#59922b,
#0544d3,
#6b0392,
#f05b4f,
#dda458,
#eacf7d,
#86797d,
#b2c326,
#6188e2,
#a748ca) !default;
@import "chartist/dist/scss/chartist";