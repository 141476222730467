@media #{$nonmobile-query} {
  .ChatScreen {
    position: fixed;
    top: 60px;
    bottom: 30px;
    left: 50%;
    width: $max-width;
    margin-left: -($max-width / 2);
    background-color: $content-bg;
    border: 1px solid $very-muted;
  }

  .ChatScreen-tabs {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    bottom: 0;
    background: $content-bg;
    border-right: 1px solid $very-muted;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .ChatScreen-tab {
    &.ChatScreen-tab-active {
      background: $inverted-bg;

      .ChatScreen-tab-name {
        font-weight: bold;
        color: $inverted-fg;
      }
    }
  }

  .ChatScreen-active-chat {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 201px;
    right: 0;
    background: $content-bg;
    overflow: hidden;
  }

  .RoomChat-messages-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 200px;
    bottom: 60px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .RoomChat-messages {
    padding: 30px 20px;
  }

  .RoomChat-message-bar {
    position: absolute;
    left: 10px;
    bottom: 10px;
    right: 210px;
  }

  .RoomChat-sidebar {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    font-size: 15px;
    border-left: 1px solid $very-muted;

    .UserName {
      font-size: 14px;
    }

    .UserName-icons {
      font-size: 14px;
    }

    .UserIcons-icon {
      margin-right: 2px;
    }
  }

  .UserChat {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .UserChat-messages {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 50px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .UserChat-message-bar {
    position: absolute;
    left: 10px;
    bottom: 10px;
    right: 10px;
  }
}

@media only screen and (max-width: 999px) {
  .ChatScreen {
    left: 0;
    right: 0;
    width: auto;
    margin: 0;
  }
}

.ChatScreen-tab {
  display: block;
  margin: 0;
  background: $content-bg;
  border-bottom: 1px solid $very-muted;
  font-size: 14px;
  line-height: 1.4;
  position: relative;

  .ChatScreen-tab-info {
    font-size: 13px;
  }

  a.ChatScreen-tab-label {
    display: block;
    padding: 15px 20px;
    border: none;
  }

  a.ChatScreen-tab-close {
    padding: 15px;
    border: none;
    position: absolute;
    right: 0;
    top: 8px;
    color: $muted;
    font-size: 16px;
  }

  &.ChatScreen-tab-join {
    a.ChatScreen-tab-label {
      color: $link-color;
    }
  }
}

.no-touch .ChatScreen-tab {
  a.ChatScreen-tab-close {
    display: none;
  }

  &:hover {
    a.ChatScreen-tab-close {
      display: block;
    }
  }

  a.ChatScreen-tab-close:hover {
    color: $content-fg;
  }

  &.ChatScreen-tab-active {
    a.ChatScreen-tab-close:hover {
      color: $inverted-fg;
    }
  }
}

.ChatScreen-tab-active {
  font-weight: normal;
}

.ChatScreen-tab-info {
  display: block;
  color: $muted;
  font-size: 14px;
}

.ChatScreen-tab-badge {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  // position: absolute;
  // left: 5px;
  // top: 10px;
}

.ChatScreen-tab-user-name,
.ChatScreen-tab-room-name {
  display: inline-block;
  vertical-align: middle;
}

.ChatScreen-banner {
  display: none;
}

.ChatScreen-back {
  display: none;
}

.RoomChat-messages {
  padding: 30px 20px;
}

.RoomChat-desc {
  // border-bottom: 2px solid $chat-divider;
  font-size: 14px;
  // color: $semi-muted;
  line-height: 1.3;
  // padding: 0 0 20px 0;
  background: $very-muted;
  padding: 20px;
}

.RoomChat-desc-text {
  padding: 20px;
  background: $content-bg;
  border-radius: 4px;
  overflow: hidden;
}

.UserChat-view-profile {
  position: absolute;
  right: 20px;
  top: 15px;
}

.UserChat-info {
  color: $muted;
  padding: 20px;
  font-size: 14px;
}

.UserChat-icons {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;

  .UserIcons {
    margin-right: 10px;
  }
}

.UserChat-info-status {
  display: inline-block;
  vertical-align: middle;
}

.UserChat-messages {
  border-top: 2px solid $chat-divider;
  padding: 20px;
}

.ChatMessageBar {
  position: relative;
  padding: 0;
  background: $chat-message-bar-outline;
  border-radius: 4px;
}

input.ChatMessageBar-input {
  width: 100%;
  padding: 10px 10px;
  border-radius: 4px;
  border: 2px solid $chat-message-bar-outline;

  &:focus {
    border: 2px solid $chat-message-bar-outline-focus;
  }
}

.RoomChat-users {
  padding: 10px 0 10px 15px;
  line-height: 1.4;
}

.ChatMessages-item {
  margin: 0 0 7px 0;
  font-size: 14px;

  &.ChatMessages-item-announcement .ChatMessages-item-content {
    &:before {
      content: "Announcement";
      display: inline;
      font-weight: bold;
      margin-right: 8px;
    }

    background: #fff8cc;
  }

  &:hover {
    .ChatMessages-item-time {
      opacity: 1;
    }
  }

  &.ChatMessages-item-self {
    .ChatMessages-item-content {
      background: #cff3e3;
    }
  }
}

.ChatMessages-item-content {
  display: inline-block;
  background: $chat-message-bg;
  padding: 8px 15px 8px 10px;
  border-radius: 15px;
  line-height: 1.3;
  max-width: 400px;
  box-sizing: border-box;
}

.ChatMessages-item-user {
  font-weight: bold;
  margin: 0 7px 0 0;
  display: inline-block;

  // font-size: 14px;
  // color: $muted;
  a,
  div {
    // color: $link-color;
    display: inline;
  }

  .UserName-icons {
    display: inline-block;

    div {
      display: inline-block;
    }
  }

  .UserName-robot,
  .UserName-guest,
  .UserName-selfish {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.ChatMessages-item-user-unverified {
  a {
    color: $muted;
  }
}

.ChatMessages-item-body {
  display: inline;

  div {
    display: inline;
  }
}

.ChatMessages-item-time {
  display: inline-block;
  opacity: 0;
  vertical-align: top;
  font-size: 11px;
  color: $chat-message-time;
  margin: 1.2em 0 0 5px;
}

a.ChatMessages-game {
  display: block;
  font-size: 14px;
  color: $muted;
  margin: 5px 0 5px 10px;
  padding: 0 0 0 30px;
  line-height: 20px;
  position: relative;
  // opacity: 0.5;
}

.ChatMessages-game-icon,
.ChatMessages-game-players,
.ChatMessages-game-time,
.ChatMessages-game-name {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.ChatMessages-game-icon {
  // display: none;
  position: absolute;
  left: 0;
}

a.ChatMessages-game-type-challenge {
  opacity: 1;

  .ChatMessages-game-players {
    font-weight: bold;
    color: $semi-muted;
  }
}

.ChatMessages-game-players {
  .GamePlayersList-player {
    display: inline-block;
    vertical-align: middle;
  }

  .GamePlayersList-player2 {
    &:before {
      content: " vs ";
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }

    .UserName {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .GamePlayersList-white2,
  .GamePlayersList-black2 {
    margin-left: 5px;
  }
}

.ChatMessages-game-time {
  margin-left: 5px;
}

.ChatMessages-game-name {
  &:before {
    content: " – ";
  }
}

a.ChatMessages-game-deleted {
  opacity: 0.25;
}

@media only screen and (max-width: 999px) {
  .ChatMessages-item-time {
    display: none;
  }
}

.RoomGameLinks {
  display: flex;
  margin: 20px 0 -10px -10px;
  flex-wrap: wrap;
  align-items: center;
}

.RoomGameLink {
  display: block;
  flex: 1 1 auto;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 38px;

  a {
    display: block;
    border: 2px solid $very-muted;
    padding: 7px 10px 7px 10px;
    background: $content-bg;
    border-radius: 15px;
    font-size: 14px;
    box-sizing: border-box;
    height: 38px;
    line-height: 1;

    &:active {
      border: 2px solid $muted;
    }
  }
}

.no-touch .RoomGameLink a:hover {
  border: 2px solid $muted;
}

.RoomGameLink-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  margin: 0 10px 0 5px;
}

.RoomGameLink-label {
  display: inline-block;
  vertical-align: middle;
}

@media #{$mobile-query} {
  .ChatScreen {
    padding-bottom: 30px;
  }

  .ChatScreen-tabs {
    display: none;
  }

  .ChatScreen-tab {
    font-size: 16px;
  }

  .ChatScreen-banner {
    display: block;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    background: $off-white;
    text-align: center;
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid $very-muted;
    z-index: 1;
  }

  .ChatScreen-banner-title {
    display: inline-block;
    padding: 0 5px;
    font-weight: bold;

    div {
      display: inline-block;
    }
  }

  .ChatScreen-banner-info {
    display: block;
    font-size: 12px;
    color: $muted;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 7px 0 5px;
  }

  .ChatScreen-back {
    display: block;
    position: absolute;
    left: 0;
    top: 0;

    a {
      display: block;
      color: $link-color;
    }
  }

  .ChatScreen-back-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    padding: 0 5px;
  }

  .ChatScreen-back-label {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    padding-bottom: 1px;
    font-weight: bold;
  }

  .ChatScreen-back-badge {
    position: absolute;
    right: -15px;
    top: 3px;
  }

  .ChatMessageBar {
    padding: 10px 15px;
    border-radius: 0;
  }

  input.ChatMessageBar-input {
    padding: 10px;
    font-size: 16px;
  }

  .UserChat-view-profile,
  .UserChat-info {
    display: none;
  }

  .ChatScreen-room-users {
    margin: 50px 0 0 0;
    padding: 10px 20px;
    line-height: 1.4;
  }

  .UserChat {
    padding-top: 30px;
  }

  .UserChat-messages {
    padding: 30px 10px 20px 10px;

    .ChatMessages-item-content {
      max-width: 295px;
    }
  }

  .RoomChat-messages-container {
    padding: 35px 0 20px 0;
  }

  .RoomChat-messages {
    padding: 30px 10px;

    .ChatMessages-item-content {
      max-width: 295px;
    }
  }

  .RoomChat-sidebar {
    display: none;
  }
}

.ChatRoomList {
  padding: 60px 20px 20px 20px;
}

.ChatRoomList-search {
  margin: 10px 0 0 0;
}

.ChatRoomList-search-input {
  width: 100%;
}

.ChatRoomList-cat {
  padding: 20px 0;
}

;

.ChatRoomList-cat-title {
  font-weight: bold;
  border-bottom: 2px solid $very-muted;
  padding: 5px 0;
}

.ChatRoomList-rooms {
  font-size: 14px;
  columns: 2;
  padding: 10px 0;
}

a.ChatRoomList-room {
  display: block;
  padding: 3px 10px;
  text-indent: -5px;
}